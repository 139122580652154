import { lazy } from "react";

const AuthWrapper = lazy(() => import("../containers/AuthWrapper"));
const Dasbboard = lazy(() => import("../containers/Main/Dashboard"));
const TransferFundCards = lazy(() => import("../containers/Main/TransferFunds"));
const TransferBetweenAccounts = lazy(() => import("../containers/Main/TransfersBetweenAccounts"));
const TransactionsHistory = lazy(
  () => import("../containers/Main/TransactionHistory")
);
const TransferBeneficiaryFunds = lazy(
  () => import("../containers/Main/TransfersBeneficiaries/TBeneficiaryFunds")
);
const BankTransfer = lazy(
  () => import("../components/ContentArea/BankTransfer")
);
const AddBank = lazy(() => import("../components/ContentArea/AddBank"));
const Congratulations = lazy(
  () => import("../components/User/Congratulations")
);
const SendMoney = lazy(
  () => import("../components/ContentArea/BankTransfer/SendMoney")
);
const ReceiveMoney = lazy(
  () => import("../components/ContentArea/BankTransfer/ReceiveMoney")
);
const CardOperations = lazy(() => import("../containers/Main/CardOperations"));
const BankTransactonHistory = lazy(
  () => import("../components/ContentArea/BankTransactonHistory")
);
const Authentication = lazy(() => import("../pages/Authentication"));
const ForgotPassword = lazy(
  () => import("../containers/Authentication/ForgotPassword")
);
const ConsumerVerification = lazy(
  () => import("../containers/Authentication/Login/ConsumerVerification")
);
const ResetPassword = lazy(() => import("../containers/Authentication/ForgotPassword/ResetPassword"));
const Enrollment = lazy(() => import("../pages/Enrollment"));
const SignupCongratulations = lazy(
  () => import("../containers/Authentication/Enrollment/EnrollmentSteps/SignupCongratulations")
);

const PasswordSetupCongratulations = lazy(() => import("../containers/Authentication/ConsumerSetup/ConsumerSteps/SuccessScreen"))

const TransfersBeneficiaries = lazy(() => import("../containers/Main/TransfersBeneficiaries"))

const TransferHistory = lazy(() => import("../containers/Main/TransfersBeneficiaries/TransferHistory"))

const ConsumerSetup = lazy(() => import("../pages/ConsumerSetup"))

export const appRoutes = [
  {
    key: "main",
    path: "/",
    component: AuthWrapper,
    nestedRoutes: [
      {
        key: "dashboard",
        path: "/dashboard",
        component: Dasbboard,
      },
      {
        key: "wallet-transfer",
        path: "/wallet-transfer",
        component: TransferFundCards,
      },
      {
        key: "beneficiaries",
        path: "/beneficiaries",
        component: TransfersBeneficiaries,
      },
      {
        key: "between-account-transfer",
        path: "/between-account-transfer",
        component: TransferBetweenAccounts,
      },
      {
        key: "transactions",
        path: "/transactions",
        component: TransactionsHistory,
      },
      {
        key: "beneficiary-transfer-funds",
        path: "/beneficiary-transfer-funds",
        component: TransferBeneficiaryFunds,
      },
      {
        key: "bank-Transfer",
        path: "/bank-Transfer",
        component: BankTransfer,
      },
      {
        key: "transfer-history",
        path: "/transfer-history",
        component: TransferHistory,
      },
      {
        key: "add-bank",
        path: "/add-bank",
        component: AddBank,
      },
      {
        key: "congratulations",
        path: "/congratulations",
        component: Congratulations,
      },
      {
        key: "send-money",
        path: "/send-money",
        component: SendMoney,
      },
      {
        key: "receive-money",
        path: "/receive-money",
        component: ReceiveMoney,
      },
      {
        key: "card-operations",
        path: "/card-operations",
        component: CardOperations,
      },
      {
        key: "bank-transfer-history",
        path: "/bank-transfer-history",
        component: BankTransactonHistory,
      },
      {
        key: "main",
        path: "/",
        component: Dasbboard,
        redirect: true,
        to: "/dashboard"
      },
    ],
  },
  {
    key: "login",
    path: "/login",
    component: Authentication,
  },
  {
    key: "setup",
    path: "/setup",
    component: ConsumerSetup
  },
  {
    key: "forgot-password",
    path: "/forgot-password",
    component: ForgotPassword,
  },
  {
    key: "consumer-verification",
    path: "/consumer-verification",
    component: ConsumerVerification,
  },
  {
    key: "reset-password",
    path: "/reset-password",
    component: ResetPassword,
  },
  {
    key: "enrollment",
    path: "/enrollment",
    component: Enrollment,
  },
  {
    key: "completion",
    path: "/completion",
    component: SignupCongratulations,
  },
  {
    key: "password-setup",
    path: "/password-success",
    component: PasswordSetupCongratulations,
  },
];
