import { getSingleMetaStyle } from "../../../ui-kit/typography/style-utils";
import "./styles.css";

const Loader = ({
  className,
}: {
  message?: string;
  className?: string;
}): JSX.Element => {
  const style = getSingleMetaStyle("borderTopColor", "primaryColor");

  return (
    <div className={`logo-container ${className!}`}>
      <div className="loader" {...style}></div>
    </div>
  );
};

export default Loader;
