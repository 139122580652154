import { B2C_URL } from "../../credentials";
import axiosAPI from "../../utils/axios";

interface createAccountInterface {
  first_name: string
  last_name: string
  email: string
  password: string
}


export const getAuthTokenAPI = async (surl: any) => {
  const b2cURL = B2C_URL();
  const baseUrl = b2cURL.split('graphql')[0] 
  const data = await axiosAPI.get(`${baseUrl?.trim()}${surl}`);
  const auth_Token = data.data ? JSON.stringify(data.data.data) : "{}";
  localStorage.setItem("Cloud_SecureURL", auth_Token);
  return auth_Token;
};

export const sendCodeOnEmail = async (email: string, reason_type: string) => {
  return await axiosAPI.post(`/identity/email/${email}/otp`, {
    reason_type,
  });
};

export const createAccount = async (apiRequest: createAccountInterface) => {
  return await axiosAPI.post(`/identity`, { ...apiRequest, reason_type: 'SU' })
}

export const sendCodeOnPhoneAuth = async (phoneNo: String, reason_type: string) => {
  
  return await axiosAPI.post(`/identity/mobile/${phoneNo}/otp`, {
    reason_type,
  });
};

export const verifyPhoneOTP = async (phoneNo: string, otp_value: string) => {
  return await axiosAPI.get(`/identity/mobile/${phoneNo}/otp/${otp_value}`);
};

/** Two Factor Authentications */

export const loginAfter2FA = async (requestDetails: any) => {
  return await axiosAPI.post("/auth/login", requestDetails);
};

export const getMfaKey = async (mfaToken: string) => {
  return await axiosAPI.get(`/mfa/${mfaToken}/key`);
};

export const enableUser2FA = async (token: string, otpValue: string) => {
  return await axiosAPI.post(`/mfa/${token}/key/confirm`, { otp1: otpValue });
};

export const verifyOTP = async (email: string, otp_value: string) => {
  return await axiosAPI.get(`/identity/email/${email}/otp/${otp_value}`);
};

/** Reset & Modify Password */

export const resetPassword = async (apiProps: any) => {
  return await axiosAPI.post("/identity/password", { ...apiProps });
};

export const changePassword = async (apiProps: any) => {
  const { old_password, new_password, token } = apiProps;
  return await axiosAPI.put(
    "/auth/password",
    { old_password, new_password },
    {
      Authorization: `Bearer ${token}`,
    }
  );
};

export const verifyConfirmationToken = async (authEventType: string, confirmationToken: string) => {
  return await axiosAPI.get(`/identity/verification/${authEventType}/${confirmationToken}`)
}

